import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import swal from "sweetalert";
import { GetNameChoose } from "../../../funcUtils";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import History from "./History";
import { useNavigate } from "react-router-dom";
import "./xd.css";

function Kubet() {
    const navigate = useNavigate();
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [historyGame, setHistoryGame] = useState(null);
    const [second, setSecond] = useState(20);
    const [minute, setMinute] = useState(0);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [total, setTotal] = useState(null);
    const [total2, setTotal2] = useState(null);
    const [setting, setSetting] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [newMoney, setNewMoney] = useState(null);
    const [current, setCurrent] = useState(null);
    const [choose, setChoose] = useState(null);
    const [lastBet, setLastBet] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isOpen2, setIsOpen2] = useState(false);
    const openPopup2 = () => {
        setIsOpen2(true);
    };
    const closePopup2 = () => {
        setIsOpen2(false);
    };
    const [activeOption, setActiveOption] = useState(null);

    const handleOptionClick = (option) => {
        setActiveOption(option);
        setNewMoney(Number(Number(option) * 1000).toLocaleString("vi-VN"));
    };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            setSetting(res.data.data[0]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/kubet/get`).then((res) => {
            setBet(res.data.data);
            setDulieunhap(new Date(res.data.data.createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/kubet/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
                setTotal2(res.data.data);
            })
            .catch(() => setTotal(null));
        getHistoryBet();
        getProfile();
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/kubet/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(new Date(res.data.data.createdAt));
                if (res.data.data.status == "end") {
                    getProfile();
                    getHistoryBet();
                }
                if (res.data.data.result == "start") {
                    const date = new Date(res.data.data.updatedAt);
                    const date0 = new Date();
                    if (date0 - date < 60000) {
                        axios.get(`${process.env.REACT_APP_API_URL}/kubet/getcurrent`).then((res) => {
                            setCurrent(res.data.data);
                        });
                    } else {
                        setCurrent(null);
                    }
                }
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    useEffect(() => {
        let curTime_second = Math.floor(20 - (date - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 20 && curTime_second >= 0) {
            setSecond(curTime_second);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(20 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 20 || curTime_second <= 0) {
                setStart(false);
                setMinute(0);
                setSecond(20);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);
    const onSubmit = (e) => {
        e.preventDefault();
        if (bet?.status != "start") {
            swal("Thông báo", "Đã hết thời gian cược, vui lòng chờ phiên tiếp theo.", "info");
            return;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return;
        }
        const formData = {
            result: choose,
            id: bet._id,
            money: Number(newMoney.replaceAll(".", "").replaceAll(",", ""))
        };
        if (Number(newMoney.replaceAll(".", "").replaceAll(",", "")) > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }
        if (!choose) {
            swal("Thất bại", "Bạn chưa lựa chọn", "error");
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/history/choosekubet`, formData)
            .then((res) => {
                swal("Đặt cược thành công", `${GetNameChoose(choose, null, "Live")}`, "success");
                setChoose(null);
                getHistoryBet();
                getProfile();
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    };
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }

    const [activeTab, setActiveTab] = useState("tab1");
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        getHistoryBet();
    };

    function getHistoryBet() {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "kubet" } })
            .then((res) => {
                setHistoryGame(res.data.data);
                getProfile();
            })
            .catch((err) => function () {});
    }
    function getProfile() {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
    }
    function thongke(num) {
        if (current) {
            const countTable = {};
            for (let i = 1; i <= 9; i++) {
                countTable[i] = { count: 0, totalMoney: 0 };
            }
            current.forEach((item) => {
                const money = item.money;
                const states = item.state;
                countTable[states].count++;
                countTable[states].totalMoney += money;
            });
            return Number(countTable[num].totalMoney).toLocaleString("vi-VN");
        } else {
            return 0;
        }
    }
    return (
        <>
            <div className="main xd">
                <Header profile={profile} />
                <div className="box-bg">
                    <div className="bg-games">
                        <div className="streaming">
                            <iframe src="https://system32-cloudfare-356783752985678522.monster/kubet2.html" loading="eager"></iframe>
                        </div>
                    </div>
                </div>
                <div className="bg-kubet">
                    <div className="timedg" style={{maxHeight:"50px"}}>
                        {bet?.status == "start" ? (
                            <>
                                <div
                                    data-v-45adac70=""
                                    className="v_circle taste_lottery_countdown xodi  v_circle_show v_circle_PC"
                                    style={{ transform: "scale(0.7)", position: "unset" }}>
                                    <div data-v-45adac70="" className="mask half">
                                        <div data-v-45adac70="" className="fill fix" style={{ transform: `rotate(${second}deg)` }}></div>
                                    </div>
                                    <div data-v-45adac70="" flex="main:center cross:center" className="pv">
                                        <span data-v-45adac70="" className="progress">
                                            {second}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ fontSize: "0.35rem" }}>Bắt đầu đặt cược</span> <br />
                                    <span style={{ opacity: "0.7" }}>Phiên {bet?.id_bet ? bet?.id_bet : "000"}</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <img src={require("./img/SV388_logo.png")} style={{ height: "40px" }} />
                                </div>
                                {bet?.status == "stop" ? (
                                    <div>
                                        <span style={{ fontSize: "0.35rem" }}>Dừng đặt cược</span> <br />
                                        <span style={{ opacity: "0.7" }}>Phiên {bet?.id_bet ? bet?.id_bet : "000"}</span>
                                    </div>
                                ) : (
                                    <div>
                                        {bet?.result ? (
                                            <>
                                                <span style={{ fontSize: "0.35rem" }}>Kết quả</span> <br />
                                                <span style={{ opacity: "0.7" }}>{bet?.result}</span>
                                            </>
                                        ) : null}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="area-chips">
                    <button className="btn" onClick={openPopup2}>
                        <DescriptionIcon sx={{ fontSize: "20px" }} />
                    </button>
                    <button className="btn">
                        <InfoOutlinedIcon sx={{ fontSize: "20px" }} />
                    </button>
                </div>
                <div className="choose-box-kubet">
                    {bet?.status == "start" ? (
                        <>
                            <div className={`button-choose-kubet vang ${choose == 1 ? "active" : ""}`} onClick={(e) => setChoose(1)}>
                                <span>1:14</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 1 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Con đôi</p>
                            </div>
                            <div className={`button-choose-kubet bdd ${choose == 2 ? "active" : ""}`} onClick={(e) => setChoose(2)}>
                                <span>1:8</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 2 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Hòa</p>
                            </div>
                            <div className={`button-choose-kubet vang ${choose == 3 ? "active" : ""}`} onClick={(e) => setChoose(3)}>
                                <span>1:14</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 3 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Cái đôi</p>
                            </div>
                            <div className={`button-choose-kubet xanh ${choose == 4 ? "active" : ""}`} onClick={(e) => setChoose(4)}>
                                <span>1:1</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 4 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Người chơi</p>
                            </div>
                            <div className={`button-choose-kubet do ${choose == 5 ? "active" : ""}`} onClick={(e) => setChoose(5)}>
                                <span>1:1</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 5 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Nhà cái</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="button-choose-kubet">
                                <span>1:14</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 1 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Con đôi</p>
                            </div>
                            <div className="button-choose-kubet">
                                <span>1:8</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 2 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Hòa</p>
                            </div>
                            <div className="button-choose-kubet">
                                <span>1:14</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 3 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Cái đôi</p>
                            </div>
                            <div className="button-choose-kubet" style={{ width: "calc(50% - 5px)" }}>
                                <span>1:1</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 4 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Người chơi</p>
                            </div>
                            <div className="button-choose-kubet" style={{ width: "calc(50% - 5px)" }}>
                                <span>1:1</span>
                                <div>
                                    {historyGame
                                        ?.filter((item) => item.state == 5 && item.status_bet == "Pending")
                                        .map((item) => item.money)
                                        .reduce((acc, curr) => acc + curr, 0)
                                        .toLocaleString("vi-VN")}
                                </div>
                                <p>Nhà cái</p>
                            </div>
                        </>
                    )}
                </div>
                <div style={{ position: "absolute", bottom: "10px", width: "96%", left: "2%" }}>
                    <div className="bet_taste_chips">
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "5" ? "active" : ""}`} onClick={() => handleOptionClick("5")}>
                            <div data-v-331b32c3="" flex="main:center cross:center" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_10">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">5K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "10" ? "active" : ""}`} onClick={() => handleOptionClick("10")}>
                            <div data-v-331b32c3="" flex="main:center cross:center" className="taste_chip">
                                <div data-v-331b32c3="" flex="main:center cross:center" className="taste_chip_base taste_chip_25">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">10K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "50" ? "active" : ""}`} onClick={() => handleOptionClick("50")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_50">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">50K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "100" ? "active" : ""}`} onClick={() => handleOptionClick("100")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_100">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">100K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "500" ? "active" : ""}`} onClick={() => handleOptionClick("500")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_200">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">500K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "1000" ? "active" : ""}`} onClick={() => handleOptionClick("1000")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_500">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">1000K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "5000" ? "active" : ""}`} onClick={() => handleOptionClick("5000")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_5">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">5000K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bet-input-panel bet_panel_taste">
                        <div data-v-331b32c3="" flex="main:justify box:justify cross:center" className="bet_taste_info">
                            <button
                                data-v-331b32c3=""
                                className="bet_taste_reset"
                                onClick={() => {
                                    setActiveOption(null);
                                    setNewMoney(null);
                                    setChoose(null);
                                }}>
                                Đặt lại
                            </button>
                            <div data-v-331b32c3="" className="bet_taste_text">
                                <div data-v-331b32c3="" flex="cross:center" className="bet_taste_money">
                                    <input
                                        data-v-331b32c3=""
                                        flex-box="8"
                                        className="bet_taste_money_bet"
                                        min={1000}
                                        value={newMoney ? newMoney : 0}
                                        onChange={(e) => setNewMoney(e.target.value)}
                                        onClick={() => setActiveOption(null)}
                                        onKeyUp={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                        name="money"
                                        type="text"
                                        placeholder="Nhập số tiền"
                                    />
                                </div>
                            </div>
                            <button data-v-331b32c3="" className="bet_taste_submit" type="submit" onClick={onSubmit}>
                                Đặt cược
                            </button>
                        </div>
                    </div>
                </div>

                <History isOpen={isOpen2} closePopup={closePopup2} />
            </div>
        </>
    );
}
export default Kubet;
