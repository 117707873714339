import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
// import AnimatedPage from "../components/AnimatedPage";
import axios from "axios";
import swal from "sweetalert";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';

import "./login.css";
import { AppContext } from "../../App";

function Login() {
    const {theme }= useContext(AppContext)
    const [showAction, setShowAction]= useState(true)
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const onSubmit = async (data) => {
        console.log(data)
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return false;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return false;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return false;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    navigate("/?notify");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div id="app">
            {/* {notify && (
                    <>
                        {notify[1]?.isShow && (
                            <div className="banner-login">
                                <img alt="" src={notify[1]?.image} />
                            </div>
                        )}
                    </>
                )}
                <div className="logo-login">
                    <img alt="" src="/images/logoxoso881.png" />
                </div>
                <AnimatedPage>
                    <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                        <div onClick={() => navigate("/register")} className="login-return">
                            <KeyboardArrowLeftOutlinedIcon className="login-return-i" /> <span>Quay lại</span>
                        </div>
                        <div className="inputs" style={{ marginTop: "0.5rem" }}>
                            <div className="input">
                                <img alt="" src="images/icon-user.svg" />
                                <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                                {errors.username ? <p>{errors.username.message}</p> : null}
                            </div>
                            <div className="input">
                                <img alt="" src="images/icon-password.svg" />
                                <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                                {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                                {errors.password ? <p>{errors.password.message}</p> : null}
                            </div>
                        </div>
                        {err ? <p>{err}</p> : null}
                        <div className="p-lg">
                            <span>Quên mật khẩu?</span>
                            <Link to="/register">
                                <i>Bạn chưa có tài khoản?</i> Đăng ký
                            </Link>
                        </div>
                        <button className="btn-red-big" type="submit">
                            ĐĂNG NHẬP
                        </button>
                    </form>
                </AnimatedPage> */}

            {/*  */}
            <div data-v-981ff8e2 className="loginAndSignup hidePhoneLogin" changetabbarshow="function () { [native code] }" >
                <div data-v-981ff8e2 className="logo-wp" >
                    <img data-v-981ff8e2 src="/images/logoxoso881.png" alt="" className="logo" />
                </div>
                <img data-v-981ff8e2 src="https://juxveohinqvpgtw.com/server/static/img/frontend/login_bg.png" className="img-wp" />
                <div data-v-981ff8e2 className="default-sheet-wp" >
                    <div data-v-981ff8e2 className="btns" >
                        <button
                            onClick={()=> setShowAction(true)}
                            data-v-981ff8e2
                            type="submit"
                            className="loginBtn van-button van-button--default van-button--normal van-button--block van-button--round"
                            style={{ color: "white", background: "rgb(83, 135, 253)", borderColor: "rgb(83, 135, 253)" }}>
                            <div data-v-981ff8e2 className="van-button__content" >
                                <span data-v-981ff8e2 className="van-button__text">
                                    Click để đăng nhập
                                </span>
                            </div>
                        </button>
                        <div onClick={()=> navigate("/register")} data-v-981ff8e2 className="registerBtn" >
                            Click để đăng ký
                        </div>
                        <div data-v-981ff8e2 role="separator" className="otherBtnDivider van-divider van-divider--hairline van-divider--content-center" >
                            Or
                        </div>
                        <div data-v-5420866a data-v-981ff8e2 className="otherBtn" >
                            <div data-v-5420866a >
                                <svg
                                    data-v-60fa4791
                                    data-v-5420866a
                                    width="36px"
                                    height="36px"
                                    viewBox="0 0 36 36"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="dynamicSVGIcon">
                                    <title>icon_servicecentre</title>
                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                        <g id="1-注册登录页" transform="translate(-38.000000, -573.000000)">
                                            <g id="编组-10备份-4" transform="translate(32.000000, 573.000000)">
                                                <g id="icon/servicecentre" transform="translate(6.000000, 0.000000)">
                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                    <g id="编组" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
                                                        <path
                                                            d="M26.2950058,20.877167 L26.1649245,21.2120948 C24.9012776,24.6358013 22.039489,27.2035812 18.4901278,28.0409007 L18.2299652,28.096722 L18.0813008,27.8920439 C17.5795587,27.2221883 16.7990708,26.8500463 16,26.8500463 C15.6840883,26.8500463 15.3681765,26.9058676 15.0708479,27.0175102 C13.9930314,27.4454735 13.3240418,28.5432924 13.4355401,29.6969327 C13.5656214,30.8505729 14.4576074,31.7995351 15.6097561,31.966999 C16.7619048,32.15307 17.8954704,31.5390357 18.3600465,30.4598238 L18.4343786,30.2737528 L18.6387921,30.2365386 C23.3774681,29.2503623 27.1684088,25.7522273 28.5063879,21.0818451 L28.5807201,20.8027386 L26.2950058,20.877167 L26.2950058,20.877167 Z"
                                                            id="路径"
                                                            fill="#5387FD"
                                                        />
                                                        <path
                                                            d="M29.1753775,11.2200817 L28.8966318,11.1828675 L28.8408827,10.903761 C27.8002323,4.59595387 22.4111498,0 16,0 C9.58885017,0 4.19976771,4.57734677 3.15911731,10.903761 L3.10336818,11.1642604 L2.82462253,11.2014746 C1.18931475,11.461974 0,12.8388995 0,14.4949314 L0,17.4720675 C0,19.3141705 1.48664344,20.8027386 3.32636469,20.8027386 L5.55632985,20.8027386 C6.15098722,20.8027386 6.65272938,20.3189539 6.65272938,19.7049196 L6.65272938,12.2620793 C6.65272938,11.7224734 6.26248548,11.275903 5.74216028,11.1828675 L5.33333333,11.1084391 L5.42624855,10.6990829 C5.94657375,8.317374 7.2659698,6.14034322 9.16144019,4.59595387 C11.0940767,3.01435031 13.5098722,2.15842368 16,2.15842368 C18.4901278,2.15842368 20.9245064,3.03295741 22.8385598,4.59595387 C24.7340302,6.14034322 26.0720093,8.317374 26.5737515,10.6990829 L26.6666667,11.1084391 L26.2578397,11.1828675 C25.7189315,11.275903 25.3472706,11.7224734 25.3472706,12.2620793 L25.3472706,19.7235267 C25.3472706,20.15149 25.5888502,20.5422392 25.9790941,20.7097031 L26.3135889,20.8585599 L28.5993031,20.8213457 L28.8780488,20.8027386 C30.6248548,20.691096 32,19.2397421 32,17.4720675 L32,14.5135385 C31.981417,12.8575066 30.8106852,11.461974 29.1753775,11.2200817 Z M4.51567944,18.7559575 L3.32636469,18.7559575 C2.67595819,18.7559575 2.13704994,18.2163516 2.13704994,17.565103 L2.13704994,14.4391101 C2.13704994,13.7878616 2.67595819,13.2482557 3.32636469,13.2482557 L4.51567944,13.2482557 L4.51567944,18.7559575 Z M29.9930314,17.751174 C29.9930314,18.4954581 29.398374,19.0908853 28.6550523,19.0908853 L27.3170732,19.0908853 L27.3170732,12.9133279 L28.6550523,12.9133279 C29.398374,12.9133279 29.9930314,13.5087551 29.9930314,14.2530391 L29.9930314,17.751174 Z"
                                                            id="形状"
                                                            fill="#231815"
                                                            className="svg-main-path"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span data-v-5420866a>CSKH</span>
                            </div>
                            <div data-v-5420866a >
                                <svg
                                    data-v-60fa4791
                                    data-v-5420866a
                                    width="36px"
                                    height="36px"
                                    viewBox="0 0 36 36"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="dynamicSVGIcon">
                                    <title>icon_app</title>
                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                        <g id="1-注册登录页" transform="translate(-126.000000, -573.000000)">
                                            <g id="编组-4" transform="translate(120.000000, 573.000000)">
                                                <g id="icon/app" transform="translate(6.000000, 0.000000)">
                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                    <g id="编组-2" transform="translate(2.500000, 3.000000)">
                                                        <path
                                                            d="M28.5,23 L28.5,27 C28.5,28.6568542 27.1568542,30 25.5,30 L5.5,30 C3.84314575,30 2.5,28.6568542 2.5,27 L2.5,23 L4.3,23 L4.3,27 C4.3,27.6627417 4.8372583,28.2 5.5,28.2 L25.5,28.2 C26.1627417,28.2 26.7,27.6627417 26.7,27 L26.7,23 L28.5,23 Z M25.5,0 C27.1568542,0 28.5,1.34314575 28.5,3 L28.5,8 L26.7,8 L26.7,3 C26.7,2.3372583 26.1627417,1.8 25.5,1.8 L5.5,1.8 C4.8372583,1.8 4.3,2.3372583 4.3,3 L4.3,8 L2.5,8 L2.5,3 C2.5,1.34314575 3.84314575,0 5.5,0 L25.5,0 Z"
                                                            id="形状结合"
                                                            fill="#292B31"
                                                            fillRule="nonzero"
                                                            className="svg-main-path"
                                                        />
                                                        <text
                                                            id="APP"
                                                            fontFamily="SFUIDisplay-Semibold, SF UI Display"
                                                            fontSize={16}
                                                            fontWeight={500}
                                                            fill="#292B31"
                                                            className="svg-main-path">
                                                            <tspan x="0.34765625" y={21}>
                                                                APP
                                                            </tspan>
                                                        </text>
                                                        <path
                                                            d="M14.1532803,24.9316394 C14.1532803,25.7234622 14.7992961,26.3653614 15.5961969,26.3653614 C16.3930978,26.3653614 17.0391136,25.7234622 17.0391136,24.9316394 C17.0391136,24.1398165 16.3930978,23.4979173 15.5961969,23.4979173 C14.7992961,23.4979173 14.1532803,24.1398165 14.1532803,24.9316394 L14.1532803,24.9316394 Z"
                                                            id="路径"
                                                            fill="#5387FD"
                                                            fillRule="nonzero"
                                                        />
                                                        <polygon
                                                            id="路径"
                                                            fill="#5387FD"
                                                            fillRule="nonzero"
                                                            points="12.3612976 3.3243738 18.8310963 3.3243738 18.8310963 5.24705479 12.3612976 5.24705479"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span data-v-5420866a>Tải APP</span>
                            </div>
                            <div data-v-5420866a >
                                <svg
                                    data-v-60fa4791
                                    data-v-5420866a
                                    width="36px"
                                    height="36px"
                                    viewBox="0 0 36 36"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="dynamicSVGIcon">
                                    <title>icon_demo</title>
                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                        <g id="1-注册登录页" transform="translate(-213.000000, -573.000000)">
                                            <g id="编组-5" transform="translate(208.000000, 573.000000)">
                                                <g id="编组-7" transform="translate(5.000000, 0.000000)">
                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                    <g id="编组" transform="translate(3.000000, 3.000000)">
                                                        <rect
                                                            id="矩形"
                                                            stroke="#292B31"
                                                            strokeWidth="1.6875"
                                                            x="0.84375"
                                                            y="0.84375"
                                                            width="28.3125"
                                                            height="28.3125"
                                                            rx={3}
                                                            className="svg-stroke"
                                                        />
                                                        <path
                                                            d="M24.375,4.6875 L24.375,15.9375 L4.6875,15.9375 L4.6875,4.6875 L24.375,4.6875 Z M22.5,6.5625 L6.5625,6.5625 L6.5625,14.0625 L22.5,14.0625 L22.5,6.5625 Z"
                                                            id="形状"
                                                            fill="#3E4045"
                                                            fillRule="nonzero"
                                                            className="svg-main-path"
                                                        />
                                                        <path
                                                            d="M20.3125,23.75 C20.8302669,23.75 21.25,24.1697331 21.25,24.6875 C21.25,25.205267 20.8302669,25.625 20.3125,25.625 C19.794733,25.625 19.375,25.205267 19.375,24.6875 C19.375,24.1697331 19.794733,23.75 20.3125,23.75 Z M22.1875,21.875 C22.7052669,21.875 23.125,22.2947331 23.125,22.8125 C23.125,23.330267 22.7052669,23.75 22.1875,23.75 C21.669733,23.75 21.25,23.330267 21.25,22.8125 C21.25,22.2947331 21.669733,21.875 22.1875,21.875 Z M18.4375,21.875 C18.9552669,21.875 19.375,22.2947331 19.375,22.8125 C19.375,23.330267 18.9552669,23.75 18.4375,23.75 C17.919733,23.75 17.5,23.330267 17.5,22.8125 C17.5,22.2947331 17.919733,21.875 18.4375,21.875 Z M20.3125,20 C20.8302669,20 21.25,20.4197331 21.25,20.9375 C21.25,21.455267 20.8302669,21.875 20.3125,21.875 C19.794733,21.875 19.375,21.455267 19.375,20.9375 C19.375,20.4197331 19.794733,20 20.3125,20 Z M9.86625,20 L9.86625,22.00875 L11.875,22.00875 L11.875,23.61625 L9.86625,23.61625 L9.86625,25.625 L8.25875001,25.625 L8.25875001,23.61625 L6.24999999,23.61625 L6.24999999,22.00875 L8.25875001,22.00875 L8.25875001,20 L9.86625,20 Z"
                                                            id="形状"
                                                            fill="#6492FC"
                                                            fillRule="nonzero"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span data-v-5420866a>Chơi thử</span>
                            </div>
                            <div data-v-5420866a >
                                <svg
                                    data-v-60fa4791
                                    data-v-5420866a
                                    width="36px"
                                    height="36px"
                                    viewBox="0 0 36 36"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="dynamicSVGIcon">
                                    <title>icon_pclogin</title>
                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                        <g id="1-注册登录页" transform="translate(-302.000000, -573.000000)">
                                            <g id="编组-3" transform="translate(296.000000, 573.000000)">
                                                <g id="icon/pcLogin" transform="translate(6.000000, 0.000000)">
                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                    <g id="编组" transform="translate(2.000000, 3.000000)" fillRule="nonzero">
                                                        <path
                                                            d="M29.5,0 L2.5,0 C1.11928571,0 0,1.12464115 0,2.51196172 L0,22.2488038 C0,23.6361244 1.11928571,24.7607656 2.5,24.7607656 L11.5357143,24.7607656 L11.5357143,28.2057416 L4.39285714,28.2057416 C3.89975,28.2057416 3.5,28.6074043 3.5,29.1028708 C3.5,29.5983373 3.89975,30 4.39285714,30 L27.6071429,30 C28.10025,30 28.5,29.5983373 28.5,29.1028708 C28.5,28.6074043 28.10025,28.2057416 27.6071429,28.2057416 L20.4642857,28.2057416 L20.4642857,24.7607656 L29.5,24.7607656 C30.8807143,24.7607656 32,23.6361244 32,22.2488038 L32,2.51196172 C32,1.12464115 30.8807143,0 29.5,0 Z M18.6785714,28.2057416 L13.3214286,28.2057416 L13.3214286,24.7607656 L18.6785714,24.7607656 L18.6785714,28.2057416 Z M30.2142857,22.2488038 C30.2142857,22.6445455 29.8938571,22.9665072 29.5,22.9665072 L2.5,22.9665072 C2.10614286,22.9665072 1.78571429,22.6445455 1.78571429,22.2488038 L1.78571429,18.3014354 L30.2142857,18.3014354 L30.2142857,22.2488038 Z M1.78571429,16.507177 L1.78571429,2.51196172 C1.78571429,2.1162201 2.10614286,1.79425837 2.5,1.79425837 L29.5,1.79425837 C29.8938571,1.79425837 30.2142857,2.1162201 30.2142857,2.51196172 L30.2142857,16.507177 L1.78571429,16.507177 Z"
                                                            id="形状"
                                                            fill="#292B31"
                                                            className="svg-main-path"
                                                        />
                                                        <path
                                                            d="M15.1071429,20.6339713 C15.1071429,21.1294421 15.5068886,21.5311005 16,21.5311005 C16.4931114,21.5311005 16.8928571,21.1294421 16.8928571,20.6339713 C16.8928571,20.1385005 16.4931114,19.7368421 16,19.7368421 C15.5068886,19.7368421 15.1071429,20.1385005 15.1071429,20.6339713 Z M13.8534286,5.98431818 C13.7139643,5.8441866 13.5311429,5.77410287 13.3483571,5.77410287 C13.1655714,5.77410287 12.98275,5.8441866 12.8432857,5.98431818 C12.5643571,6.26458134 12.5643571,6.7190311 12.8432857,6.99929426 L18.1465714,12.3279904 C18.2860357,12.468122 18.4688571,12.5382057 18.6516429,12.5382057 C18.8344286,12.5382057 19.01725,12.468122 19.1567143,12.3279904 C19.4356429,12.0477273 19.4356429,11.5932775 19.1567143,11.3130144 L13.8534286,5.98431818 Z M13.8534286,10.0046411 C13.7196233,9.86982371 13.5378481,9.79416723 13.3483571,9.79442518 C13.1588701,9.79418709 12.9771022,9.86984054 12.8432857,10.0046411 C12.5643571,10.2849043 12.5643571,10.7393541 12.8432857,11.0196172 L14.3585357,12.5421172 C14.4923522,12.6769178 14.6741201,12.7525713 14.8636071,12.7523331 C15.0530942,12.7525713 15.2348621,12.6769178 15.3686786,12.5421172 C15.6476071,12.2618541 15.6476429,11.8074043 15.3686786,11.5271411 L13.8534286,10.0046411 L13.8534286,10.0046411 Z"
                                                            id="形状"
                                                            fill="#5387FD"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span data-v-5420866a>Máy tính</span>
                            </div>
                        </div>
                        <div data-v-981ff8e2 className="toback" >
                            <span data-v-981ff8e2>Xem trước trang</span>
                        </div>
                    </div>
                </div>
                {/**/}
                {/**/}
                <div data-v-981ff8e2 className="van-loading van-loading--circular" style={{ display: "none" }} >
                    <span data-v-981ff8e2 className="van-loading__spinner van-loading__spinner--circular">
                        <svg data-v-981ff8e2 viewBox="25 25 50 50" className="van-loading__circular">
                            <circle data-v-981ff8e2 cx={50} cy={50} r={20} fill="none" />
                        </svg>
                    </span>
                </div>
                {/*  */}
                {showAction && 
                <div data-v-70664b6d data-v-981ff8e2 className="action-sheet-wp" >
                    <div onClick={()=> setShowAction(false)} data-v-70664b6d className="pullDown" >
                        <img
                            data-v-70664b6d
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAVCAYAAAAaX42MAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAPKADAAQAAAABAAAAFQAAAABHDqQpAAADy0lEQVRYCWM8XRvZr/XnUN5XJsGf95mMWsybF7UxDCNwsjauSvHfuRruf+/Zr7HYTWL8XCX7l+ffByaYHx+w6j17yqwXbNMw/QRMbCjSRxoyLST/Xl2p9Pu8HMz9X5gE/jF+qlL8zfvvDQtMEET/ZmBnuM5qvfOdkmCAY+KCH8hyg529f34Ch8D9L8u0fx0IZGX4ieLcz0wif5iucdhVfmPk+4csA1Ko93ufu97t4x9PNMWXIMsNZvbxxqRUndsnPxj82onhWZAfQX5lBHngYHOOosTvG1vUfp3Uwuahe6yGj15waHlbV8+4gk1+oMUON2SrSfy9tlXl9xkVbG65xWZ+7QWrho997ZT7YA/DFB1tSIlR/nVqhvi/+9wwMRj9k4Hr/3U22w0fFHmiBlMyP18XskTj99Eojv9fUPwCcvdLJsWv99lNCi3r582G+QNDEUgCnyFvmGR+3mE3L7WsXzAZZshA0KDIUfp9errE33s86PbjixysHgYZQCiZ3GE1ufOKQz2Q3sn8WF+htND7q1tVf5/SZ2L4i+5XBkLZD6eHYSaBCgJgMp8o+vchJ0wMRuMLSZgaatKgNoPG3yN5yNUozPx3TFK/bnNYVFvULeyBiWGjCXoYpAlW1Gv+OhzAzvANQ89rZvnvd9nM8pHzCjbLyBU73JDuofj73DLpvzcE0c0gtQrFcDy6gcj8o60ZOhI/rm1FrsyR5UHJ/AWzlrdtw9RbyOLksvf3FwiIvr+zWfPXERtsyfcRi87LxywGAaQ0kkjyMMzhxxsTclV+nuwW+feEHSYGo38w8vy/wWq9zLBpTQxMjBz6RH10s8bvk+X8/16yousHNSCusVp1WDQurUWXI8Qny8MgQwklc1CVAEzmGdYNc5YQcgSyPKhJKP330lqF35ekkMVB7H8MzAzX2WyOvBZU8XUsnPABXZ4YPtkehhlOKJkjV/owPdhoUAAK3Xu/QfP3UXf0JiFI/VNmjff3WY2ibBtm7sCmn1gxij0MswjUBFX9caJV6N8zNpgYjAY1666z28wzaViZChNDpvFlEVCD/yabFU69yOYQw6aah0GWEYqlF8xKX+6xmmbCkjkodYj9uLkeV5PwJqvlxXeC2t5WRf1PifEMMWqo6mGYhYTy4W1Ws4vfGfnuAZuEAbiahOTkf5j9+GiaeBhmIajzrf73WAO2khamBpmmVgmPbCY6m6YeBlkGq0vVfp2wwVYYwRxEr6YqzT0M8xAomcv+ubBB7s8VcZgYiKZ3Z4RuHoZ5EtSgUPpzsZjj/1fW+8x6e+k9qgIAgvTEoApI97EAAAAASUVORK5CYII="
                            alt=""
                        />
                        <img
                            data-v-70664b6d
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAVCAYAAAAaX42MAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAPKADAAQAAAABAAAAFQAAAABHDqQpAAADy0lEQVRYCWM8XRvZr/XnUN5XJsGf95mMWsybF7UxDCNwsjauSvHfuRruf+/Zr7HYTWL8XCX7l+ffByaYHx+w6j17yqwXbNMw/QRMbCjSRxoyLST/Xl2p9Pu8HMz9X5gE/jF+qlL8zfvvDQtMEET/ZmBnuM5qvfOdkmCAY+KCH8hyg529f34Ch8D9L8u0fx0IZGX4ieLcz0wif5iucdhVfmPk+4csA1Ko93ufu97t4x9PNMWXIMsNZvbxxqRUndsnPxj82onhWZAfQX5lBHngYHOOosTvG1vUfp3Uwuahe6yGj15waHlbV8+4gk1+oMUON2SrSfy9tlXl9xkVbG65xWZ+7QWrho997ZT7YA/DFB1tSIlR/nVqhvi/+9wwMRj9k4Hr/3U22w0fFHmiBlMyP18XskTj99Eojv9fUPwCcvdLJsWv99lNCi3r582G+QNDEUgCnyFvmGR+3mE3L7WsXzAZZshA0KDIUfp9errE33s86PbjixysHgYZQCiZ3GE1ufOKQz2Q3sn8WF+htND7q1tVf5/SZ2L4i+5XBkLZD6eHYSaBCgJgMp8o+vchJ0wMRuMLSZgaatKgNoPG3yN5yNUozPx3TFK/bnNYVFvULeyBiWGjCXoYpAlW1Gv+OhzAzvANQ89rZvnvd9nM8pHzCjbLyBU73JDuofj73DLpvzcE0c0gtQrFcDy6gcj8o60ZOhI/rm1FrsyR5UHJ/AWzlrdtw9RbyOLksvf3FwiIvr+zWfPXERtsyfcRi87LxywGAaQ0kkjyMMzhxxsTclV+nuwW+feEHSYGo38w8vy/wWq9zLBpTQxMjBz6RH10s8bvk+X8/16yousHNSCusVp1WDQurUWXI8Qny8MgQwklc1CVAEzmGdYNc5YQcgSyPKhJKP330lqF35ekkMVB7H8MzAzX2WyOvBZU8XUsnPABXZ4YPtkehhlOKJkjV/owPdhoUAAK3Xu/QfP3UXf0JiFI/VNmjff3WY2ibBtm7sCmn1gxij0MswjUBFX9caJV6N8zNpgYjAY1666z28wzaViZChNDpvFlEVCD/yabFU69yOYQw6aah0GWEYqlF8xKX+6xmmbCkjkodYj9uLkeV5PwJqvlxXeC2t5WRf1PifEMMWqo6mGYhYTy4W1Ws4vfGfnuAZuEAbiahOTkf5j9+GiaeBhmIajzrf73WAO2khamBpmmVgmPbCY6m6YeBlkGq0vVfp2wwVYYwRxEr6YqzT0M8xAomcv+ubBB7s8VcZgYiKZ3Z4RuHoZ5EtSgUPpzsZjj/1fW+8x6e+k9qgIAgvTEoApI97EAAAAASUVORK5CYII="
                            alt=""
                        />
                    </div>
                    <div data-v-70664b6d className="back-btn-wp" >
                        <i data-v-70664b6d className="icon-back" /> Quay{" "}
                    </div>
                    <div onClick={()=> navigate("/register")} data-v-70664b6d className="btn-to-resister" >
                        {" "}
                        Đăng ký{" "}
                    </div>
                    <div data-v-70664b6d className="van-tabs van-tabs--line" >
                        <div className="van-tabs__wrap" >
                            <div role="tablist" className="van-tabs__nav van-tabs__nav--line" style={{ background: "transparent" }} >
                                <div role="tab" aria-selected="true" className="van-tab van-tab--active" >
                                    <span className="van-tab__text van-tab__text--ellipsis">Tài khoản</span>
                                </div>
                                <div className="van-tabs__line" style={{ transform: "translateX(0px) translateX(-50%)" }}  />
                            </div>
                        </div>
                        <div className="van-tabs__content" >
                            <div data-v-70664b6d role="tabpanel" className="van-tab__pane" style={{}} >
                                <form data-v-70664b6d className="van-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div data-v-70664b6d className="van-cell van-field" >
                                        <div className="van-field__left-icon" >
                                            <AccountCircleIcon style={{fontSize: 24, color: theme=== "dark" ? "#fff" : "#000"}} />
                                        </div>
                                        <div className="van-cell__value van-cell__value--alone van-field__value" >
                                            <div className="van-field__body" >
                                                <input {...register("username", { required: true })} type="text" autoComplete="off" name="username" placeholder="Nhập tài khoản" className="van-field__control" />
                                            </div>
                                            {errors.username ? <p>{errors.username.message}</p> : null}
                                        </div>
                                    </div>
                                    <div data-v-70664b6d className="van-cell van-field" >
                                        <div className="van-field__left-icon" >
                                            <PasswordIcon style={{fontSize: 24, color: theme=== "dark" ? "#fff" : "#000"}} />
                                        </div>
                                        <div className="van-cell__value van-cell__value--alone van-field__value" >
                                            <div className="van-field__body" >
                                                <input {...register("password", { required: true })} type="password" autoComplete="off" name="password" placeholder="6-15 ký tự chữ và số" className="van-field__control" />
                                                <div className="van-field__right-icon" >
                                                    <img data-v-70664b6d src="/mobile/static/img/icon_capital_dark.15673cd5.svg" alt="" style={{ display: "none" }} />
                                                    <span data-v-70664b6d className="iconfont icon-icon_show" style={{ display: "none" }} />
                                                    <span data-v-70664b6d className="iconfont icon-icon_hide" />
                                                </div>
                                                {errors.password ? <p>{errors.password.message}</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/**/}
                                    <div data-v-70664b6d className="terms-wp" >
                                        <div data-v-70664b6d >
                                            <span data-v-70664b6d className="iconfont icon-icon_checkbox_nor" />
                                            <span data-v-70664b6d className="iconfont icon-icon_checkbox_sel" style={{ display: "none" }} />
                                            <span data-v-70664b6d className="terms">
                                                Nhớ tài khoản và mật khẩu
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", textAlign: "left", marginBottom: 10}}>
                                        {err ? <p className="text-theme-dark">{err}</p> : null}
                                    </div>
                                    <div data-v-70664b6d className="btns" >
                                        <button
                                            data-v-70664b6d
                                            type="submit"
                                            className="loginBtn van-button van-button--default van-button--normal van-button--block van-button--round"
                                            style={{ color: "white", background: "rgb(83, 135, 253)", borderColor: "rgb(83, 135, 253)" }}>
                                            <div data-v-70664b6d className="van-button__content" >
                                                <span data-v-70664b6d className="van-button__text">
                                                    Click để đăng nhập
                                                </span>
                                            </div>
                                        </button>
                                        <div
                                            data-v-70664b6d
                                            role="separator"
                                            className="otherBtnDivider van-divider van-divider--hairline van-divider--content-center"
                                            >
                                            Or
                                        </div>
                                        <div data-v-5420866a data-v-70664b6d className="otherBtn" >
                                            <div data-v-5420866a >
                                                <svg
                                                    data-v-60fa4791
                                                    data-v-5420866a
                                                    width="36px"
                                                    height="36px"
                                                    viewBox="0 0 36 36"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    className="dynamicSVGIcon">
                                                    <title>icon_servicecentre</title>
                                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                                        <g id="1-注册登录页" transform="translate(-38.000000, -573.000000)">
                                                            <g id="编组-10备份-4" transform="translate(32.000000, 573.000000)">
                                                                <g id="icon/servicecentre" transform="translate(6.000000, 0.000000)">
                                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                                    <g id="编组" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
                                                                        <path
                                                                            d="M26.2950058,20.877167 L26.1649245,21.2120948 C24.9012776,24.6358013 22.039489,27.2035812 18.4901278,28.0409007 L18.2299652,28.096722 L18.0813008,27.8920439 C17.5795587,27.2221883 16.7990708,26.8500463 16,26.8500463 C15.6840883,26.8500463 15.3681765,26.9058676 15.0708479,27.0175102 C13.9930314,27.4454735 13.3240418,28.5432924 13.4355401,29.6969327 C13.5656214,30.8505729 14.4576074,31.7995351 15.6097561,31.966999 C16.7619048,32.15307 17.8954704,31.5390357 18.3600465,30.4598238 L18.4343786,30.2737528 L18.6387921,30.2365386 C23.3774681,29.2503623 27.1684088,25.7522273 28.5063879,21.0818451 L28.5807201,20.8027386 L26.2950058,20.877167 L26.2950058,20.877167 Z"
                                                                            id="路径"
                                                                            fill="#5387FD"
                                                                        />
                                                                        <path
                                                                            d="M29.1753775,11.2200817 L28.8966318,11.1828675 L28.8408827,10.903761 C27.8002323,4.59595387 22.4111498,0 16,0 C9.58885017,0 4.19976771,4.57734677 3.15911731,10.903761 L3.10336818,11.1642604 L2.82462253,11.2014746 C1.18931475,11.461974 0,12.8388995 0,14.4949314 L0,17.4720675 C0,19.3141705 1.48664344,20.8027386 3.32636469,20.8027386 L5.55632985,20.8027386 C6.15098722,20.8027386 6.65272938,20.3189539 6.65272938,19.7049196 L6.65272938,12.2620793 C6.65272938,11.7224734 6.26248548,11.275903 5.74216028,11.1828675 L5.33333333,11.1084391 L5.42624855,10.6990829 C5.94657375,8.317374 7.2659698,6.14034322 9.16144019,4.59595387 C11.0940767,3.01435031 13.5098722,2.15842368 16,2.15842368 C18.4901278,2.15842368 20.9245064,3.03295741 22.8385598,4.59595387 C24.7340302,6.14034322 26.0720093,8.317374 26.5737515,10.6990829 L26.6666667,11.1084391 L26.2578397,11.1828675 C25.7189315,11.275903 25.3472706,11.7224734 25.3472706,12.2620793 L25.3472706,19.7235267 C25.3472706,20.15149 25.5888502,20.5422392 25.9790941,20.7097031 L26.3135889,20.8585599 L28.5993031,20.8213457 L28.8780488,20.8027386 C30.6248548,20.691096 32,19.2397421 32,17.4720675 L32,14.5135385 C31.981417,12.8575066 30.8106852,11.461974 29.1753775,11.2200817 Z M4.51567944,18.7559575 L3.32636469,18.7559575 C2.67595819,18.7559575 2.13704994,18.2163516 2.13704994,17.565103 L2.13704994,14.4391101 C2.13704994,13.7878616 2.67595819,13.2482557 3.32636469,13.2482557 L4.51567944,13.2482557 L4.51567944,18.7559575 Z M29.9930314,17.751174 C29.9930314,18.4954581 29.398374,19.0908853 28.6550523,19.0908853 L27.3170732,19.0908853 L27.3170732,12.9133279 L28.6550523,12.9133279 C29.398374,12.9133279 29.9930314,13.5087551 29.9930314,14.2530391 L29.9930314,17.751174 Z"
                                                                            id="形状"
                                                                            fill="#231815"
                                                                            className="svg-main-path"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span data-v-5420866a>CSKH</span>
                                            </div>
                                            <div data-v-5420866a >
                                                <svg
                                                    data-v-60fa4791
                                                    data-v-5420866a
                                                    width="36px"
                                                    height="36px"
                                                    viewBox="0 0 36 36"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    className="dynamicSVGIcon">
                                                    <title>icon_app</title>
                                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                                        <g id="1-注册登录页" transform="translate(-126.000000, -573.000000)">
                                                            <g id="编组-4" transform="translate(120.000000, 573.000000)">
                                                                <g id="icon/app" transform="translate(6.000000, 0.000000)">
                                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                                    <g id="编组-2" transform="translate(2.500000, 3.000000)">
                                                                        <path
                                                                            d="M28.5,23 L28.5,27 C28.5,28.6568542 27.1568542,30 25.5,30 L5.5,30 C3.84314575,30 2.5,28.6568542 2.5,27 L2.5,23 L4.3,23 L4.3,27 C4.3,27.6627417 4.8372583,28.2 5.5,28.2 L25.5,28.2 C26.1627417,28.2 26.7,27.6627417 26.7,27 L26.7,23 L28.5,23 Z M25.5,0 C27.1568542,0 28.5,1.34314575 28.5,3 L28.5,8 L26.7,8 L26.7,3 C26.7,2.3372583 26.1627417,1.8 25.5,1.8 L5.5,1.8 C4.8372583,1.8 4.3,2.3372583 4.3,3 L4.3,8 L2.5,8 L2.5,3 C2.5,1.34314575 3.84314575,0 5.5,0 L25.5,0 Z"
                                                                            id="形状结合"
                                                                            fill="#292B31"
                                                                            fillRule="nonzero"
                                                                            className="svg-main-path"
                                                                        />
                                                                        <text
                                                                            id="APP"
                                                                            fontFamily="SFUIDisplay-Semibold, SF UI Display"
                                                                            fontSize={16}
                                                                            fontWeight={500}
                                                                            fill="#292B31"
                                                                            className="svg-main-path">
                                                                            <tspan x="0.34765625" y={21}>
                                                                                APP
                                                                            </tspan>
                                                                        </text>
                                                                        <path
                                                                            d="M14.1532803,24.9316394 C14.1532803,25.7234622 14.7992961,26.3653614 15.5961969,26.3653614 C16.3930978,26.3653614 17.0391136,25.7234622 17.0391136,24.9316394 C17.0391136,24.1398165 16.3930978,23.4979173 15.5961969,23.4979173 C14.7992961,23.4979173 14.1532803,24.1398165 14.1532803,24.9316394 L14.1532803,24.9316394 Z"
                                                                            id="路径"
                                                                            fill="#5387FD"
                                                                            fillRule="nonzero"
                                                                        />
                                                                        <polygon
                                                                            id="路径"
                                                                            fill="#5387FD"
                                                                            fillRule="nonzero"
                                                                            points="12.3612976 3.3243738 18.8310963 3.3243738 18.8310963 5.24705479 12.3612976 5.24705479"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span data-v-5420866a>Tải APP</span>
                                            </div>
                                            <div data-v-5420866a >
                                                <svg
                                                    data-v-60fa4791
                                                    data-v-5420866a
                                                    width="36px"
                                                    height="36px"
                                                    viewBox="0 0 36 36"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    className="dynamicSVGIcon">
                                                    <title>icon_demo</title>
                                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                                        <g id="1-注册登录页" transform="translate(-213.000000, -573.000000)">
                                                            <g id="编组-5" transform="translate(208.000000, 573.000000)">
                                                                <g id="编组-7" transform="translate(5.000000, 0.000000)">
                                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                                    <g id="编组" transform="translate(3.000000, 3.000000)">
                                                                        <rect
                                                                            id="矩形"
                                                                            stroke="#292B31"
                                                                            strokeWidth="1.6875"
                                                                            x="0.84375"
                                                                            y="0.84375"
                                                                            width="28.3125"
                                                                            height="28.3125"
                                                                            rx={3}
                                                                            className="svg-stroke"
                                                                        />
                                                                        <path
                                                                            d="M24.375,4.6875 L24.375,15.9375 L4.6875,15.9375 L4.6875,4.6875 L24.375,4.6875 Z M22.5,6.5625 L6.5625,6.5625 L6.5625,14.0625 L22.5,14.0625 L22.5,6.5625 Z"
                                                                            id="形状"
                                                                            fill="#3E4045"
                                                                            fillRule="nonzero"
                                                                            className="svg-main-path"
                                                                        />
                                                                        <path
                                                                            d="M20.3125,23.75 C20.8302669,23.75 21.25,24.1697331 21.25,24.6875 C21.25,25.205267 20.8302669,25.625 20.3125,25.625 C19.794733,25.625 19.375,25.205267 19.375,24.6875 C19.375,24.1697331 19.794733,23.75 20.3125,23.75 Z M22.1875,21.875 C22.7052669,21.875 23.125,22.2947331 23.125,22.8125 C23.125,23.330267 22.7052669,23.75 22.1875,23.75 C21.669733,23.75 21.25,23.330267 21.25,22.8125 C21.25,22.2947331 21.669733,21.875 22.1875,21.875 Z M18.4375,21.875 C18.9552669,21.875 19.375,22.2947331 19.375,22.8125 C19.375,23.330267 18.9552669,23.75 18.4375,23.75 C17.919733,23.75 17.5,23.330267 17.5,22.8125 C17.5,22.2947331 17.919733,21.875 18.4375,21.875 Z M20.3125,20 C20.8302669,20 21.25,20.4197331 21.25,20.9375 C21.25,21.455267 20.8302669,21.875 20.3125,21.875 C19.794733,21.875 19.375,21.455267 19.375,20.9375 C19.375,20.4197331 19.794733,20 20.3125,20 Z M9.86625,20 L9.86625,22.00875 L11.875,22.00875 L11.875,23.61625 L9.86625,23.61625 L9.86625,25.625 L8.25875001,25.625 L8.25875001,23.61625 L6.24999999,23.61625 L6.24999999,22.00875 L8.25875001,22.00875 L8.25875001,20 L9.86625,20 Z"
                                                                            id="形状"
                                                                            fill="#6492FC"
                                                                            fillRule="nonzero"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span data-v-5420866a>Chơi thử</span>
                                            </div>
                                            <div data-v-5420866a >
                                                <svg
                                                    data-v-60fa4791
                                                    data-v-5420866a
                                                    width="36px"
                                                    height="36px"
                                                    viewBox="0 0 36 36"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    className="dynamicSVGIcon">
                                                    <title>icon_pclogin</title>
                                                    <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" opacity="0.898297991">
                                                        <g id="1-注册登录页" transform="translate(-302.000000, -573.000000)">
                                                            <g id="编组-3" transform="translate(296.000000, 573.000000)">
                                                                <g id="icon/pcLogin" transform="translate(6.000000, 0.000000)">
                                                                    <rect id="矩形" x={0} y={0} width={36} height={36} />
                                                                    <g id="编组" transform="translate(2.000000, 3.000000)" fillRule="nonzero">
                                                                        <path
                                                                            d="M29.5,0 L2.5,0 C1.11928571,0 0,1.12464115 0,2.51196172 L0,22.2488038 C0,23.6361244 1.11928571,24.7607656 2.5,24.7607656 L11.5357143,24.7607656 L11.5357143,28.2057416 L4.39285714,28.2057416 C3.89975,28.2057416 3.5,28.6074043 3.5,29.1028708 C3.5,29.5983373 3.89975,30 4.39285714,30 L27.6071429,30 C28.10025,30 28.5,29.5983373 28.5,29.1028708 C28.5,28.6074043 28.10025,28.2057416 27.6071429,28.2057416 L20.4642857,28.2057416 L20.4642857,24.7607656 L29.5,24.7607656 C30.8807143,24.7607656 32,23.6361244 32,22.2488038 L32,2.51196172 C32,1.12464115 30.8807143,0 29.5,0 Z M18.6785714,28.2057416 L13.3214286,28.2057416 L13.3214286,24.7607656 L18.6785714,24.7607656 L18.6785714,28.2057416 Z M30.2142857,22.2488038 C30.2142857,22.6445455 29.8938571,22.9665072 29.5,22.9665072 L2.5,22.9665072 C2.10614286,22.9665072 1.78571429,22.6445455 1.78571429,22.2488038 L1.78571429,18.3014354 L30.2142857,18.3014354 L30.2142857,22.2488038 Z M1.78571429,16.507177 L1.78571429,2.51196172 C1.78571429,2.1162201 2.10614286,1.79425837 2.5,1.79425837 L29.5,1.79425837 C29.8938571,1.79425837 30.2142857,2.1162201 30.2142857,2.51196172 L30.2142857,16.507177 L1.78571429,16.507177 Z"
                                                                            id="形状"
                                                                            fill="#292B31"
                                                                            className="svg-main-path"
                                                                        />
                                                                        <path
                                                                            d="M15.1071429,20.6339713 C15.1071429,21.1294421 15.5068886,21.5311005 16,21.5311005 C16.4931114,21.5311005 16.8928571,21.1294421 16.8928571,20.6339713 C16.8928571,20.1385005 16.4931114,19.7368421 16,19.7368421 C15.5068886,19.7368421 15.1071429,20.1385005 15.1071429,20.6339713 Z M13.8534286,5.98431818 C13.7139643,5.8441866 13.5311429,5.77410287 13.3483571,5.77410287 C13.1655714,5.77410287 12.98275,5.8441866 12.8432857,5.98431818 C12.5643571,6.26458134 12.5643571,6.7190311 12.8432857,6.99929426 L18.1465714,12.3279904 C18.2860357,12.468122 18.4688571,12.5382057 18.6516429,12.5382057 C18.8344286,12.5382057 19.01725,12.468122 19.1567143,12.3279904 C19.4356429,12.0477273 19.4356429,11.5932775 19.1567143,11.3130144 L13.8534286,5.98431818 Z M13.8534286,10.0046411 C13.7196233,9.86982371 13.5378481,9.79416723 13.3483571,9.79442518 C13.1588701,9.79418709 12.9771022,9.86984054 12.8432857,10.0046411 C12.5643571,10.2849043 12.5643571,10.7393541 12.8432857,11.0196172 L14.3585357,12.5421172 C14.4923522,12.6769178 14.6741201,12.7525713 14.8636071,12.7523331 C15.0530942,12.7525713 15.2348621,12.6769178 15.3686786,12.5421172 C15.6476071,12.2618541 15.6476429,11.8074043 15.3686786,11.5271411 L13.8534286,10.0046411 L13.8534286,10.0046411 Z"
                                                                            id="形状"
                                                                            fill="#5387FD"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span data-v-5420866a>Máy tính</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/**/}
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}
export default Login;
