import React from "react";
import "./gamebai.css";
import { Grid } from "@mui/material";

const Gamebai = () => {
    return (
        <div data-v-216c589c data-v-6662729e className="homeGameChunk chess" >
            {/**/}
            {/**/}
            <div data-v-7762520a data-v-216c589c className="homeTitle title" >
                <div data-v-7762520a className="title" >
                    <span data-v-7762520a className="line" />
                    <span data-v-7762520a className="name">
                        Game bài
                    </span>
                </div>
                {/**/}
            </div>
            {/**/}
            <div data-v-216c589c className="subShow chess" >
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect chess chess-gameItem"
                            
                            style={{ animation: "0.25s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp chess" >
                                {/**/}
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/mobile/static/img/img_chess_1.51974468.png" className="croupier chess" />
                                {/**/}
                                <img data-v-216c589c alt="" className="logo v8" />
                                <div data-v-216c589c className="rebate" >
                                    Hoàn 1.1%
                                </div>
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>V8</span>
                            </p>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect chess chess-gameItem"
                            
                            style={{ animation: "0.5s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp chess" >
                                {/**/}
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/mobile/static/img/img_chess_2.f3c3f917.png" className="croupier chess" />
                                {/**/}
                                <img data-v-216c589c alt="" className="logo rich88" />
                                <div data-v-216c589c className="rebate" >
                                    Hoàn 1.1%
                                </div>
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>RICH88 CHESS</span>
                            </p>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect chess chess-gameItem"
                            
                            style={{ animation: "0.75s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp chess" >
                                {/**/}
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/mobile/static/img/img_chess_3.3b29812e.png" className="croupier chess" />
                                {/**/}
                                <img data-v-216c589c alt="" className="logo ky" />
                                <div data-v-216c589c className="rebate" >
                                    Hoàn 1.1%
                                </div>
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>KY</span>
                            </p>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect chess chess-gameItem"
                            
                            style={{ animation: "1s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp chess" >
                                {/**/}
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/mobile/static/img/img_chess_4.2ada9331.png" className="croupier chess" />
                                {/**/}
                                <img data-v-216c589c alt="" className="logo jili" />
                                <div data-v-216c589c className="rebate" >
                                    Hoàn 1.1%
                                </div>
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>JILI GAME BÀI</span>
                            </p>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect chess chess-gameItem"
                            
                            style={{ animation: "1.25s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp chess" >
                                {/**/}
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/mobile/static/img/img_chess_5.98a06d1e.png" className="croupier chess" />
                                {/**/}
                                <img data-v-216c589c alt="" className="logo mg" />
                                <div data-v-216c589c className="rebate" >
                                    Hoàn 1.1%
                                </div>
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>MG GAME BÀI</span>
                            </p>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect chess chess-gameItem"
                            
                            style={{ animation: "1.5s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp chess" >
                                {/**/}
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/mobile/static/img/img_chess_6.2fe6254f.png" className="croupier chess" />
                                {/**/}
                                <img data-v-216c589c alt="" className="logo bbin" />
                                <div data-v-216c589c className="rebate" >
                                    Hoàn 1.1%
                                </div>
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>BBIN CHESS</span>
                            </p>
                            {/**/}
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/**/}
            {/**/}
        </div>
    );
};

export default Gamebai;
