import React from "react";
import "./taixiu.css";
import { Grid } from "@mui/material";

const Taixiu = () => {
    return (
        <div data-v-6662729e className="third-game-feature" >
            <div data-v-7762520a className="homeTitle" >
                <div data-v-7762520a className="title" >
                    <span data-v-7762520a className="line" />
                    <span data-v-7762520a className="name">
                        Tài xỉu
                    </span>
                </div>
                {/**/}
            </div>
            <div className="game-list" >
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <div className="feature-game-item list-effect"  style={{ animation: "1.4s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div className="main" >
                                <img
                                    alt=""
                                    data-src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/luckywin/main_icon/sd.png?v=1.8.22"
                                    src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/luckywin/main_icon/sd.png?v=1.8.22"
                                    lazy="loaded"
                                />
                            </div>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="feature-game-item list-effect"  style={{ animation: "1.2s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div className="main" >
                                <img
                                    alt=""
                                    data-src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/luckywin/main_icon/dice.png?v=1.8.22"
                                    src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/luckywin/main_icon/dice.png?v=1.8.22"
                                    lazy="loaded"
                                />
                            </div>
                            {/**/}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="feature-game-item list-effect"  style={{ animation: "2.4s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div className="main" >
                                <img
                                    alt=""
                                    data-src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/luckywin/main_icon/slotLs.png?v=1.8.22"
                                    src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/luckywin/main_icon/slotLs.png?v=1.8.22"
                                    lazy="loaded"
                                />
                            </div>
                            {/**/}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Taixiu;
