import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
// import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../../components/AnimatedPage";

function RechargeBankAuto() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [bank, setBank] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState([]);
    const [newMoney, setNewMoney] = useState(null);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const id = "auto"
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getbank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(null));
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                if (res.data.data) setPayment(res.data.data);
            })
            .catch((err) => function () {});
    }, []);
    const [activeOption, setActiveOption] = useState("Techcombank");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const onSubmit = (data) => {
        if (load == true) return false;
        const formData = {
            money: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
            type_payment: "NẠP",
            //detail: data.detail,
            detail: id,
            status_payment: "Pending",
            user: profile._id
        };
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) <= 0 || typeof Number(data.money.replaceAll(".", "").replaceAll(",", "")) !== "number") {
            swal("Thông báo", "Vui lòng nhập số tiền hợp lệ", "error");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) < setting?.minnap) {
            swal("Chú ý", `Số tiền nạp tối thiểu là ${(setting?.minnap).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > setting?.maxnap) {
            swal("Chú ý", `Số tiền nạp tối đa là ${(setting?.maxnap).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        setLoading(true);
        setLoad(true);
        if (id != "auto") {
            if (payment) {
                const count = payment.filter((obj) => obj.status_payment === "Pending" && obj.type_payment === "NẠP");
                if (count.length > 1) {
                    swal("Thông báo", `Bạn đang có ${count.length} lệnh nạp tiền chưa xử lý, không thể tạo thêm lệnh nạp tiền.`, "error");
                    setLoad(false);
                    return false;
                }
            }
            axios
                .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
                .then((res) => {
                    swal("Nạp tiền thành công", "Tiền sẽ được cộng trong vòng 5 phút. Nếu quá lâu vui lòng liên hệ CSKH để được xử lý.", "success");
                    setLoading(false);
                    setLoad(false);
                    navigate("/recharge_record");
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
                    setLoad(false);
                    setLoading(false);
                });
        } else {
            axios
                .post(`${process.env.REACT_APP_API_URL}/bank/createTrans`, {
                    amount: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
                    type: activeOption
                })
                .then((res) => {
                    window.location.href = res.data.data.url;
                    setLoading(false);
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Ngân hàng bảo trì, vui lòng chọn ngân hàng khác", "error");
                    setLoading(false);
                    setLoad(false);
                });
        }
    };
    const handleCopyClick = (text) => {
        const textToCopy = text;
        const tempInput = document.createElement("input");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        swal("Thành công", "Copy thành công", "success");
    };
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {/* <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Nạp tiền {id == "momo" ? "qua ví Momo" : id == "bank" ? "qua ngân hàng" : "siêu tốc"}</h1>
            </div> */}
            <div className="main">
                <AnimatedPage>
                    <div className="content_profile">
                        <div className="content_bank">
                            {bank ? (
                                bank.map((item) =>
                                    item.isShow && item.name_bank.toLowerCase() == "momo" && id == "momo" ? (
                                        <div className="item-banks">
                                            {item?.qr && (
                                                <div>
                                                    <img src={item?.qr} />
                                                </div>
                                            )}
                                            <span className="copystk" onClick={() => handleCopyClick(item.stk)}>
                                                Copy
                                            </span>
                                            <div>
                                                Số Momo: <b>{item.stk}</b>
                                            </div>
                                            <div>
                                                Người nhận: <b>{item.fullname}</b>
                                            </div>
                                            {profile?.username ? (
                                                <div>
                                                    Nội dung chuyển khoản:{" "}
                                                    <b>
                                                        {item.title} {profile?.username}
                                                    </b>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : item.isShow && item.name_bank.toLowerCase() != "momo" && id == "bank" ? (
                                        <div className="item-banks">
                                            {item?.qr && (
                                                <div>
                                                    <img src={item?.qr} />
                                                </div>
                                            )}
                                            <span className="copystk" onClick={() => handleCopyClick(item.stk)}>
                                                Copy
                                            </span>
                                            <div>
                                                STK: <b>{item.stk}</b>
                                            </div>
                                            <div>
                                                Ngân hàng: <b>{item.name_bank}</b>
                                            </div>
                                            <div>
                                                Người nhận: <b>{item.fullname}</b>
                                            </div>
                                            {profile?.username ? (
                                                <div>
                                                    Nội dung chuyển khoản:{" "}
                                                    <b>
                                                        {item.title} {profile?.username}
                                                    </b>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null
                                )
                            ) : (
                                <div>Vui lòng liên hệ chăm sóc khách hàng</div>
                            )}
                        </div>
                        {id == "auto" && (
                            <ul className="tab-navigation tab-bank">
                                <li className={activeOption === "Techcombank" ? "active" : ""} onClick={() => handleOptionClick("Techcombank")}>
                                    <img src="https://api.vietqr.io/img/TCB.png" />
                                </li>
                                <li className={activeOption === "Vietcombank" ? "active" : ""} onClick={() => handleOptionClick("Vietcombank")}>
                                    <img src="https://api.vietqr.io/img/VCB.png" />
                                </li>
                                <li className={activeOption === "Eximbank" ? "active" : ""} onClick={() => handleOptionClick("Eximbank")}>
                                    <img src="https://api.vietqr.io/img/EIB.png" />
                                </li>
                                <li className={activeOption === "BIDV" ? "active" : ""} onClick={() => handleOptionClick("BIDV")}>
                                    <img src="https://api.vietqr.io/img/BIDV.png" />
                                </li>
                                <li className={activeOption === "MBBANK" ? "active" : ""} onClick={() => handleOptionClick("MBBANK")}>
                                    <img src="https://api.vietqr.io/img/MB.png" />
                                </li>
                                <li className={activeOption === "TPBANK" ? "active" : ""} onClick={() => handleOptionClick("TPBANK")}>
                                    <img src="https://api.vietqr.io/img/TPB.png" />
                                </li>
                                <li className={activeOption === "VIB" ? "active" : ""} onClick={() => handleOptionClick("VIB")}>
                                    <img src="https://api.vietqr.io/img/VIB.png" />
                                </li>
                                <li className={activeOption === "MOMO" ? "active" : ""} onClick={() => handleOptionClick("MOMO")}>
                                    <img src="/images/logo_momo.png" />
                                </li>
                            </ul>
                        )}
                        {bank && (
                            <>
                                <form className="form-lg form-box" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <div className="input">
                                            <label>Nhập số tiền</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value={newMoney}
                                                {...register("money")}
                                                onClick={() => setNewMoney(null)}
                                                onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString('vi-VN'))}
                                                placeholder="Nhập số tiền"
                                            />
                                        </div>
                                        <div style={{ display: "none" }}>
                                            {" "}
                                            <input
                                                className="ipadd"
                                                type="text"
                                                {...register("detail", { required: true })}
                                                placeholder="Nhập mã giao dịch ngân hàng"
                                                defaultValue="Nạp"
                                            />
                                        </div>
                                        {errors.money ? <p style={{ color: "red" }}>{errors.money.message}</p> : null}
                                        <button type="submit" className="btn-submit">
                                            Xác nhận
                                        </button>
                                    </div>
                                </form>

                                <div className="text_choose_center huongdan">
                                    <div className="title" style={{ margin: "0.2rem 0 0.4rem", padding: "0", fontWeight: "700" }}>
                                        Hướng dẫn nạp tiền
                                    </div>
                                    <ul>
                                        <li>Chuyển khoản đến thông tin ngân hàng/Momo ở trên.</li>
                                        <li>Nạp tối tiểu 100,000 VNĐ - Tối đa 350,000,000 VNĐ/đơn.</li>
                                        <li>Vui lòng ghi đúng nội dung chuyển khoản gồm có cả tên đăng nhập của bạn</li>
                                        <li>
                                            Sau khi chuyển khoản thành công quý khách điền số tiền đã chuyển khoản vào ô "Nhập số tiền" và bấm xác nhận, tiền sẽ được cộng trong
                                            vòng 5 phút.
                                        </li>
                                        <li>Nếu sau 5 phút tiền không tự động cộng vào tài khoản quý khách, vui lòng liên hệ CSKH để được hỗ trợ nhanh nhất.</li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                </AnimatedPage>
            </div>
        </>
    );
}
export default RechargeBankAuto;
