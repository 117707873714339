import React from "react";
import "./banca.css";
import { Grid } from "@mui/material";

const Banca = () => {
    return (
        <div data-v-216c589c data-v-6662729e className="homeGameChunk fish" >
            <div data-v-7762520a data-v-216c589c className="homeTitle title" >
                <div data-v-7762520a className="title" >
                    <span data-v-7762520a className="line" />
                    <span data-v-7762520a className="name">
                        Bắn cá
                    </span>
                </div>
            </div>
            <div data-v-216c589c className="subShow fish" >
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "0.25s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/jili.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo jili" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>JILI FISH</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "0.5s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/mg.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo mg" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>MG FISH</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "0.75s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/cq9.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo cq9" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>CQ9 FISH</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "1s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/ka.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo ka" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>KA BẮN CÁ</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "1.25s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/funta.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo funta" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>FTG BẮN CÁ</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "1.5s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/jdb.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo jdb" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>JDB</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "1.75s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/fc.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo fc" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>FC FISH</span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            data-v-216c589c
                            className="game-item shadow-div list-effect fish fish-gameItem"
                            
                            style={{ animation: "2s ease-in-out 0s 1 normal forwards running list-effect-ani" }}>
                            <div data-v-216c589c className="imgsWp fish" >
                                <img data-v-216c589c src="https://juxveohinqvpgtw.com/server/static/img/thirdgame/mobile/fish/bbin.png?v=1.8.22" className="croupier fish" />
                                <img data-v-216c589c alt="" className="logo bbin" />
                            </div>
                            <p data-v-216c589c className="name">
                                <span data-v-216c589c>BBIN</span>
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Banca;
