import React, { useEffect, useState } from "react";
import { GetNameChoose } from "../../../funcUtils";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import swal from "sweetalert";

const History = ({ isOpen, closePopup }) => {
    const [historyGame, setHistoryGame] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    useEffect(() => {
        if (isOpen) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "kubet" } })
                .then((res) => {
                    setHistoryGame(res.data.data);
                })
                .catch((err) => {});
        }
    }, [isOpen]);

    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    return (
        <>
            <div className={isOpen ? "history-sidebar active" : "history-sidebar"}>
                <div className={isOpen ? "history-backdrop active" : "history-backdrop"} onClick={closePopup}>
                    <div className="history-main" style={{ background: "rgba(0,0,0,0.5)" }}>
                        <div className="history-header" style={{ background: "rgba(0,0,0,1)" }}>
                            Lịch Sử Tham Gia
                        </div>
                        <div className="history-content">
                            {historyGame != null ? (
                                <div className="content-history">
                                    {historyGame?.map((item, key) => (
                                        <>
                                            <div
                                                className="item_inner"
                                                onClick={(e) => {
                                                    setLs(item);
                                                    setShow(true);
                                                    e.stopPropagation();
                                                }}>
                                                <div className="item_history">
                                                    <div className="title_item_history">
                                                        <span className="sanh"> {item.sanh}</span>
                                                        <span
                                                            className={`type_state ${
                                                                item.status_bet === "Pending"
                                                                    ? "pending"
                                                                    : item.status_bet === "Win"
                                                                    ? "win"
                                                                    : item.status_bet === "Lose"
                                                                    ? "lose"
                                                                    : "cancelled"
                                                            }`}>
                                                            {item.status_bet === "Pending"
                                                                ? "Chờ kết quả"
                                                                : item.status_bet === "Win"
                                                                ? "Thắng"
                                                                : item.status_bet === "Lose"
                                                                ? "Thua"
                                                                : "Đã hủy"}
                                                        </span>
                                                    </div>
                                                    <div className="id_history_sanh">Phiên cược: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                                    <div className="id_history_sanh">{GetNameChoose(item?.state?.toString(), null, "Live")}</div>
                                                </div>
                                                <div className="money_history">
                                                    <span className="money">{Number(item.money).toLocaleString()}</span>
                                                    <div className="time_choose">{formatDate(new Date(item.createdAt))}</div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <div onClick={closePopup} className="history-close">
                            <CloseIcon sx={{ fontSize: "0.55rem" }} />
                        </div>
                    </div>
                </div>
            </div>
            {isShow === true && ls.status_bet !== "Pending" ? (
                <>
                    <div className="modal" style={{ zIndex: "9999999" }}>
                        <div className="modaloverlay"></div>
                        <div className="modalbody">
                            <div>
                                <div className="modalinner" style={{ padding: "10px 15px" }}>
                                    <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                        Chi tiết cược
                                    </div>

                                    {ls.id_bet ? (
                                        <>
                                            <div className="lsgd-table">
                                                <div>Trò chơi</div>
                                                <div>Live Kubet</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Trận</div>
                                                <div>{ls.sanh}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Phiên</div>
                                                <div>{ls?.id_bet?.id_bet ? ls?.id_bet?.id_bet : ls?.id_bet}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Thời gian</div>
                                                <div>{formatDate(new Date(ls.createdAt))}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Đặt cược</div>
                                                <div>{GetNameChoose(ls?.state, null, "Live")}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Tổng Cược</div>
                                                <div>{Number(ls.money).toLocaleString()} </div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Tổng thắng</div>
                                                <div>{Number(ls.moneythang).toLocaleString()} </div>
                                            </div>
                                        </>
                                    ) : null}
                                    <div>
                                        <div className="modalformcontrols">
                                            <button
                                                onClick={() => setShow(false)}
                                                className="popup-close"
                                                style={{
                                                    background: "red",
                                                    boxShadow: "none",
                                                    textShadow: "none"
                                                }}>
                                                ĐÓNG
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default History;
