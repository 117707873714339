import React from "react";

const UserProfileIcon = () => {
    return (
        <svg
            data-v-60fa4791=""
            data-v-1db014e2=""
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="dynamicSVGIcon">
            <title>icon_personalinfo_2</title>
            <g id="草稿" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g id="15-个人中心icon改3备份" transform="translate(-16.000000, -801.000000)">
                    <g id="编组-8备份" transform="translate(0.000000, 555.000000)">
                        <g id="编组-7备份-4" transform="translate(0.000000, 234.000000)">
                            <g id="icon/personalinfo/2" transform="translate(16.000000, 12.000000)">
                                <rect id="矩形" x={0} y={0} width={24} height={24} />
                                <g id="编组" transform="translate(2.000000, 2.500000)" fillRule="nonzero">
                                    <path
                                        d="M3.59066181,14.125 L8.60088757,14.125 C8.93490263,14.125 9.1854139,13.875 9.1854139,13.5416667 C9.1854139,13.25 8.93490261,12.9583333 8.60088757,12.9583333 L3.59066181,12.9583333 C3.25664675,12.9583333 3.00613546,13.2083333 3.00613546,13.5416667 C3.00613546,13.875 3.25664675,14.125 3.59066181,14.125 Z"
                                        id="路径"
                                        fill="#333333"
                                    />
                                    <path
                                        d="M14.9054217,0 C16.6590007,0 18.0785646,1.54166668 18.0785646,3.45833334 L18.074,3.282 L18.0785646,5.28261024 L16.9930157,5.28261024 L16.9930157,3.45833334 C16.9930157,2.16666666 16.0744743,1.125 14.9471735,1.125 L3.17314298,1.125 C2.04584218,1.125 1.1273008,2.16666668 1.1273008,3.45833334 L1.1273008,15.5416667 C1.1273008,16.8333333 2.04584218,17.875 3.17314298,17.875 L4.718,17.875 L6.71895084,17.8710643 L6.71895084,19 L3.17314298,19 C1.41956396,19 0,17.4583333 0,15.5416667 L0,3.45833334 C0,1.54166666 1.41956396,0 3.17314298,0 Z"
                                        id="形状结合"
                                        fill="#333333"
                                    />
                                    <path
                                        d="M7.59884242,10.125 C7.89110558,10.125 8.14161687,9.875 8.14161687,9.54166666 C8.14161687,9.25 7.89110558,8.95833334 7.59884242,8.95833334 L3.54890991,8.95833334 C3.25664675,8.95833334 3.00613546,9.20833334 3.00613546,9.54166666 C3.00613546,9.83333332 3.25664675,10.125 3.54890991,10.125 L7.59884242,10.125 Z M10.1039553,5.625 C10.1039553,5.33333334 9.81169214,5.04166666 9.47767708,5.04166666 L3.59066181,5.04166666 C3.25664675,5.04166666 2.96438358,5.29166666 2.96438358,5.625 C2.96438358,5.91666666 3.25664675,6.20833334 3.59066181,6.20833334 L9.51942896,6.20833334 C9.85344401,6.20833334 10.1039553,5.95833334 10.1039553,5.625 Z"
                                        id="形状"
                                        fill="#333333"
                                    />
                                    <path
                                        d="M14.2791434,6 C16.3667375,6 18.0785646,7.75 18.0785646,9.875 C18.0785647,11.125 17.4940383,12.2916667 16.4919932,13 L16.2832337,13.1666667 L16.4919932,13.1666667 C18.5378353,14.1666667 20.0409031,16.4583333 19.9991512,18.4583333 C19.9991512,18.75 19.7486399,19 19.4563767,19 C19.1641136,19 18.9136023,18.75 18.9136023,18.4583333 C18.9136023,16.625 16.8260082,13.75 14.2373916,13.75 C11.6487749,13.75 9.56118083,16.5833333 9.56118083,18.4583333 C9.56118083,18.75 9.31066954,19 9.01840638,19 C8.72614322,19 8.47563193,18.75 8.47563193,18.4583333 C8.47563193,16.4583333 10.0204515,14.1666667 12.0245418,13.1666667 L12.2750531,13.0416667 L12.0662937,13 C11.0642486,12.2916667 10.4797222,11.125 10.4797222,9.875 C10.4797222,7.75 12.1915494,6 14.2791434,6 Z M14.2791434,7.08333334 C12.7760757,7.08333334 11.5652711,8.33333334 11.5652711,9.83333334 C11.5652711,11.3333333 12.7760757,12.5833333 14.2791434,12.5833333 C15.7822112,12.5833333 16.9930157,11.375 16.9930157,9.83333334 C16.9930157,8.33333334 15.7822112,7.08333334 14.2791434,7.08333334 Z"
                                        id="形状结合"
                                        fill="#5387FD"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default UserProfileIcon;
