import React, { useEffect, useState } from "react";
import { GetNameChoose } from "../../funcUtils";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import swal from "sweetalert";

const History = ({ isOpen, closePopup }) => {
    const [historyGame, setHistoryGame] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    useEffect(() => {
        if (isOpen) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/history/historyus`, {})
                .then((res) => {
                    setHistoryGame(res.data.data);
                })
                .catch((err) => {});
        }
    }, [isOpen]);

    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai",
        "Bến Tre"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];
    const checkCancel = (item) => {
        const datexs = new Date(item.id_bet);
        const ngayxs = datexs.getDate();
        const thangxs = datexs.getMonth() + 1;
        const namxs = datexs.getFullYear();
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
        const minute = currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();

        if (item.sanh === "XSMB" && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1800) {
            return false;
        } else if (XSMT.includes(item?.sanh) && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1700) {
            return false;
        } else if (XSMN.includes(item?.sanh) && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1600) {
            return false;
        } else {
            return true;
        }
    };
    return (
        <>
            <div className={isOpen ? "history-sidebar active" : "history-sidebar"}>
                <div className={isOpen ? "history-backdrop active" : "history-backdrop"} onClick={closePopup}>
                    <div className="history-main">
                        <div className="history-header">Lịch Sử Tham Gia</div>
                        <div className="history-content">
                            {historyGame != null ? (
                                <div className="content-history">
                                    {historyGame?.map((item, key) => (
                                        <>
                                            {item.type ? (
                                                <div
                                                    className="item_inner"
                                                    onClick={(e) => {
                                                        console.log(item);
                                                        setLs(item);
                                                        setShow(true);
                                                        e.stopPropagation();
                                                    }}>
                                                    <div className="item_history">
                                                        <div className="title_item_history">
                                                            <span className="sanh"> {item?.sanh}</span>
                                                            <span
                                                                className={`type_state ${
                                                                    item.status_bet === "Pending"
                                                                        ? "pending"
                                                                        : item.status_bet === "Win"
                                                                        ? "win"
                                                                        : item.status_bet === "Lose"
                                                                        ? "lose"
                                                                        : "cancelled"
                                                                }`}>
                                                                {item.status_bet === "Pending"
                                                                    ? "Chờ xổ"
                                                                    : item.status_bet === "Win"
                                                                    ? "Thắng"
                                                                    : item.status_bet === "Lose"
                                                                    ? "Thua"
                                                                    : "Đã hủy"}
                                                            </span>
                                                            {item?.status_bet === "Pending" && (
                                                                <>
                                                                    {(item?.sanh == "XSMB" || XSMN.includes(item?.sanh) || XSMT.includes(item?.sanh)) && (
                                                                        <>
                                                                            {checkCancel(item) && (
                                                                                <span
                                                                                    className="cancel-bet type_state"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.target.style.display = "none";
                                                                                        const currentDate = new Date();
                                                                                        const day = currentDate.getDate();
                                                                                        const month = currentDate.getMonth() + 1;
                                                                                        const year = currentDate.getFullYear();
                                                                                        const formattedDate = `${day < 10 ? "0" + day : day}/${
                                                                                            month < 10 ? "0" + month : month
                                                                                        }/${year}`;
                                                                                        const minute =
                                                                                            currentDate.getMinutes() < 10
                                                                                                ? "0" + currentDate.getMinutes()
                                                                                                : currentDate.getMinutes();
                                                                                        if (
                                                                                            item.sanh === "XSMB" &&
                                                                                            Number(currentDate.getHours() + "" + minute) >= 1800 &&
                                                                                            Number(currentDate.getHours() + "" + minute) < 1915
                                                                                        ) {
                                                                                            swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                                            e.stopPropagation();
                                                                                            return false;
                                                                                        } else if (
                                                                                            XSMT.includes(item?.sanh) &&
                                                                                            item?.id_bet == formattedDate &&
                                                                                            Number(currentDate.getHours() + "" + minute) >= 1700 &&
                                                                                            Number(currentDate.getHours() + "" + minute) < 1815
                                                                                        ) {
                                                                                            swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                                            e.stopPropagation();
                                                                                            return false;
                                                                                        } else if (
                                                                                            XSMN.includes(item?.sanh) &&
                                                                                            item?.id_bet == formattedDate &&
                                                                                            Number(currentDate.getHours() + "" + minute) >= 1600 &&
                                                                                            Number(currentDate.getHours() + "" + minute) < 1715
                                                                                        ) {
                                                                                            swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                                            e.stopPropagation();
                                                                                            return false;
                                                                                        }
                                                                                        axios
                                                                                            .patch(`${process.env.REACT_APP_API_URL}/history/cancel`, {
                                                                                                id: item?._id,
                                                                                                userId: item?.user?._id,
                                                                                                money: item?.money
                                                                                            })
                                                                                            .then((res) => {
                                                                                                setShow(false);
                                                                                                swal("Hủy cược thành công", "", "success").then((value) => {
                                                                                                    axios
                                                                                                        .get(`${process.env.REACT_APP_API_URL}/history/historyus`, {})
                                                                                                        .then((res) => {
                                                                                                            setHistoryGame(res.data.data);
                                                                                                        })
                                                                                                        .catch((err) => {});
                                                                                                });
                                                                                            })
                                                                                            .catch((err) => {
                                                                                                setShow(false);
                                                                                                swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
                                                                                                e.target.style.display = "inline-block";
                                                                                            });
                                                                                    }}>
                                                                                    &times; Hủy cược
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="id_history_sanh">Phiên cược: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                                        <div className="id_history_sanh">{GetNameChoose(item.state, item.type)}</div>
                                                    </div>
                                                    <div className="money_history">
                                                        <span className="money">{Number(item.money).toLocaleString("vi-VN")}₫</span>
                                                        <div className="time_choose">{formatDate(new Date(item.createdAt))}</div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </>
                                    ))}
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <div onClick={closePopup} className="history-close">
                            <CloseIcon sx={{ fontSize: "0.55rem" }} />
                        </div>
                    </div>
                </div>
            </div>
            {isShow === true && ls.status_bet !== "Pending" ? (
                <>
                    <div className="modal" style={{ zIndex: "9999999" }}>
                        <div className="modaloverlay"></div>
                        <div className="modalbody">
                            <div>
                                <div className="modalinner" style={{ padding: "10px 15px" }}>
                                    <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                        Chi tiết cược
                                    </div>

                                    {ls.id_bet ? (
                                        <>
                                            <div className="lsgd-table">
                                                <div>Trò chơi</div>
                                                <div>{ls.sanh}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Phiên</div>
                                                <div>{ls.id_bet.id_bet ? ls.id_bet.id_bet : ls.id_bet}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Thời gian</div>
                                                <div>{formatDate(new Date(ls.createdAt))}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Đặt cược</div>
                                                <div>{GetNameChoose(ls.state, ls.type, ls.sanh)}</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Tổng Cược</div>
                                                <div>{Number(ls.money).toLocaleString("vi-VN")} ₫</div>
                                            </div>
                                            <div className="lsgd-table">
                                                <div>Tổng thắng</div>
                                                <div>{Number(ls.moneythang).toLocaleString("vi-VN")} ₫</div>
                                            </div>
                                        </>
                                    ) : null}
                                    <div>
                                        <div className="modalformcontrols">
                                            <button
                                                onClick={() => setShow(false)}
                                                className="popup-close"
                                                style={{
                                                    background: "red",
                                                    boxShadow: "none",
                                                    textShadow: "none"
                                                }}>
                                                ĐÓNG
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default History;
